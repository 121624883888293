<div class="svi-database-filter-group">
  <form [formGroup]="form">

    <div class="svi-database-filter-sub-group">
      <select class="form-control" formControlName="district">
        <option value="" i18n>Select District</option>
        <option *ngFor="let district of districts" [value]="district.id">{{district.name}}</option>
      </select>
      <input type="text" class="form-control" placeholder="Name" i18n-placeholder formControlName="name">
    </div>

    <div class="svi-database-filter-sub-group">
      <select class="form-control" formControlName="party">
        <option value="" i18n>Select Party</option>
        <option *ngFor="let party of parties" [value]="party.id">{{party.name}}</option>
      </select>
      <select class="form-control form-control-small" formControlName="incumbent">
        <option value="" i18n>Incumbent</option>
        <option [ngValue]="true" i18n>Yes</option>
        <option [ngValue]="false" i18n>No</option>
      </select>
      <select class="form-control form-control-small" formControlName="gender">
        <option value="" i18n>Gender</option>
        <option [value]="1" i18n>Female</option>
        <option [value]="2" i18n>Male</option>
        <option [value]="3" i18n>Other</option>
      </select>
      <select class="form-control d-none" formControlName="elected">
        <option value="" i18n>Elected</option>
        <option [ngValue]="true" i18n>Yes</option>
        <option [ngValue]="false" i18n>No</option>
      </select>
    </div>

    <div class="button-row">
      <button class="button button-primary icon-left" (click)="onSearch()" [disabled]="disabled">
        <i class="fa fa-search"></i>
        <ng-container i18n>Search</ng-container>
      </button>
      <button class="button button-primary" (click)="onReset()" [disabled]="!form.dirty">
        <ng-container i18n>Reset</ng-container>
      </button>
    </div>

  </form>
</div>