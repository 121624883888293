export const environment = {
  production: true,
  portierUrl: 'https://8qcuctx6eb.execute-api.eu-central-1.amazonaws.com/staging/graphql',
  sharing: {
    twitterUsername: 'smartvoteCH',
    pageGrabberUrl: 'http://page-grabber.smartvote.ch/screenshot'
  },
  emailerUrl: 'https://7degjxh594.execute-api.eu-central-1.amazonaws.com/staging/send-email',
  emailerFromAddress: 'smartwielen.lu<noreply@smartwielen.lu>',
  contactFormToAddress: 'info@smartwielen.lu',
  matomo: {
    url: 'https://analytics.smartvote.ch',
    siteId: 1
  }
}
